<i18n>
{
  "de": {
    "links": {
      "portfolio": "Liegenschaften",
      "report": "Absenkpfad",
      "reida": "Reporting",
      "scenario": "Szenarien",
      "settings": "Einstellungen",
      "license": "Lizenz",
      "import": "Import & Export",
      "account": "Account",
      "portfolioImport": "Portfolio importieren",
      "management": "Verwaltung"
    },
    "simulationInProgress": "Berechnung läuft",
    "buildingsInQueue": "{n} Liegenschaft | {n} Liegenschaften"
  }
}
</i18n>

<template>
  <header class="c-layout-header">
    <!-- Global menu -->
    <nav class="primary-nav">
      <router-link id="header-logo" :to="{ path: '/' }">
        <img src="/app_logo.png" width="174" height="40" alt="Co2mpass logo" />
      </router-link>
      <div class="header-buttons">
        <VersionNumber />

        <AccountLink />
      </div>
    </nav>

    <!-- Tabs -->
    <nav
      v-if="loggedIn && portfolio && hasPortfolioPermissions"
      v-sticky
      sticky-offset="{ top: 0 }"
      sticky-z-index="8"
      class="secondary-nav"
    >
      <div class="secondary-nav-items">
        <router-link :to="{ name: 'portfolioBuildings', params: { portfolio_id: portfolio.id } }">
          {{ $t('links.portfolio') }}
        </router-link>
        <router-link :to="{ name: 'report', params: { portfolio_id: portfolio.id } }">
          {{ $t('links.report') }}
        </router-link>
        <router-link :to="{ name: 'scenarios', params: { portfolio_id: portfolio.id } }">
          {{ $t('links.scenario') }}
        </router-link>
        <router-link :to="{ name: 'reida', params: { portfolio_id: portfolio.id } }">
          {{ $t('links.reida') }}
        </router-link>
        <router-link
          v-if="getPortfolioPermission('EDIT_BUILDINGS')"
          :to="{ name: 'import', params: { portfolio_id: portfolio.id } }"
        >
          {{ $t('links.import') }}
        </router-link>
        <router-link :to="{ name: 'settings', params: { portfolio_id: portfolio.id } }">
          {{ $t('links.settings') }}
        </router-link>
        <router-link :to="{ name: 'license', params: { portfolio_id: portfolio.id } }">
          {{ $t('links.license') }}
        </router-link>
        <router-link v-if="isAdmin" :to="{ name: 'adminPortfolioDetails', params: { portfolio_id: portfolio.id } }">
          {{ $t('links.management') }}
        </router-link>
      </div>

      <!-- Builidng simulation queue -->
      <div v-if="buildingSimulationQueue(this.portfolio.id).length > 0" class="main-layout-queue">
        <div class="text">
          <BuildingQueuedIndicator />
          <span class="label">{{ $t('simulationInProgress') }}</span>
        </div>
        <span class="count">{{ $tc('buildingsInQueue', buildingSimulationQueue(portfolio.id).length) }}</span>
      </div>
    </nav>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'

import AccountLink from '@/components/shared/AccountLink.vue'
import VersionNumber from '@/components/shared/VersionNumber.vue'
import BuildingQueuedIndicator from '@/components/shared/BuildingQueuedIndicator.vue'

export default {
  components: {
    AccountLink,
    VersionNumber,
    BuildingQueuedIndicator,
  },

  props: {
    portfolio: {
      type: Object,
    },
  },

  computed: {
    ...mapGetters({
      buildingSimulationQueue: 'queue/buildingSimulationQueue',
      loggedIn: 'account/loggedIn',
      isAdmin: 'account/admin',
      getPortfolioPermission: 'permissions/getPortfolioPermission',
      hasPortfolioPermissions: 'permissions/hasPortfolioPermissions',
    }),
  },
}
</script>

<style lang="scss" scoped>
.c-layout-header {
  background-color: #fff;
  position: relative;

  & a {
    font-size: 15px;
    font-weight: 400;

    @media screen and (max-width: 1560px) {
      font-size: 14px;
    }

    @media screen and (max-width: 1380px) {
      font-size: 13px;
    }

    @media screen and (max-width: 1280px) {
      font-size: 14px;
    }
  }

  & #header-logo {
    display: inherit;
    flex: none;
    border-radius: var(--box-radius);
  }

  & nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-s) var(--spacing-m);
  }

  /* Top nav (incl. logo) */
  & .primary-nav {
    height: 65px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: var(--hairline-width) solid var(--hairline-color);
    padding: var(--spacing-m) var(--spacing-m);

    & .header-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;

      & > * {
        border-radius: 40px;

        &:hover {
          background-color: #f4f4f4;
        }
      }
    }
  }

  /* 2nd nav (page links and builidng simulation queue) */
  & .secondary-nav {
    height: var(--sticky-header-height);
    background-color: #fff;
    border-bottom: var(--hairline-width) solid var(--hairline-color);

    & .secondary-nav-items {
      display: flex;
      flex-direction: row;
      gap: 2px;

      & a {
        padding: 8px 12px;
        border-radius: 40px;
        background-color: transparent;
        transition: all 0.2s ease-out;
        white-space: nowrap;

        &:hover {
          background-color: #f4f4f4;
        }

        @media screen and (max-width: 1480px) {
          padding: 8px 8px;
        }

        @media screen and (max-width: 1320px) {
          padding: 6px 6px;
        }

        @media screen and (max-width: 1280px) {
          padding: 8px 12px;
        }

        @media screen and (max-width: 960px) {
          padding: 8px 8px;
        }
      }
    }

    & a.router-link-active {
      position: relative;
      font-weight: 600;
      background-color: #f4f4f4;

      &::after {
        content: '';
        position: absolute;
        bottom: -12.5px;
        left: 0;
        right: 0;
        height: 3px;
        width: 100%;
        background-color: #36b908;
      }
    }

    & .license-info {
      padding-left: var(--spacing-l);
      color: #a23939;
    }
  }

  & .main-layout-queue {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    & .text {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6px;

      & .label {
        font-size: 13px;
        font-weight: 400;
      }
    }

    & .count {
      font-size: 13px;
      font-weight: 400;
      color: #888;
    }
  }
}
</style>
