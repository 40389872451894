<template>
  <div class="c-menu">
    <button
      ref="button"
      type="button"
      class="button"
      :class="{ 'has-selection': hasSelection }"
      :disabled="disabled"
      @click="onButtonClick"
    >
      <slot name="icon" />
      <span v-if="buttonText" :title="buttonText" class="button-text">{{ buttonText }}</span>
      <div ref="icon" class="action-icon-wrapper">
        <img
          v-if="!hasSelection"
          class="action-icon"
          src="/icons/chevron-down.svg"
          :class="isOpen ? 'arrow-up' : 'arrow-down'"
        />
        <img v-else class="action-icon" src="/icons/x-white.svg" />
      </div>
    </button>
    <div :class="dropdownClasses">
      <slot name="header" />
      <ul ref="options">
        <slot name="options" />
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stayOpen: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    hasSelection: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
    }
  },

  computed: {
    dropdownClasses() {
      return {
        'menu-dropdown': true,
        'menu-left': this.left,
        'menu-right': !this.left,
        'menu-open': this.isOpen,
      }
    },
  },

  watch: {
    isOpen(state) {
      // Add event listener only if menu is expanded. Prevents listeners multiplication
      if (state) {
        document.addEventListener('click', this.onDocumentClick)
      } else {
        document.removeEventListener('click', this.onDocumentClick)
      }
    },
  },

  methods: {
    onButtonClick(e) {
      if (this.hasSelection && this.$refs.icon?.contains(e.target)) {
        // Clicked on the icon when selection is active
        this.$emit('reset')
        this.isOpen = false
      } else {
        // Clicked on the button or the arrow
        this.isOpen = !this.isOpen
      }
    },

    onDocumentClick(e) {
      if (!this.$refs.button?.contains(e.target) && (!this.stayOpen || !this.$refs.options?.contains(e.target))) {
        // Handle click outside the menu button or options container
        this.isOpen = false
      } else if (this.hasSelection && this.$refs.icon?.contains(e.target)) {
        // Handle click on the icon when selection is active
        this.$emit('reset')
        this.isOpen = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-menu {
  position: relative;

  & .button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: var(--spacing-xs);
    padding: 6px 12px 6px 16px;

    & svg {
      flex: none;
    }

    & .button-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.has-selection {
      color: white;
      border: black;
      background-color: black;

      & .action-icon {
        // The icon correction to keep proportions
        width: 20px;
        margin-top: 2px;
        margin-right: 3px;
        opacity: 0.5;
      }
    }

    &:hover .action-icon {
      opacity: 0.5;
    }

    & .action-icon-wrapper {
      flex-shrink: 0;
    }

    & .action-icon {
      flex: 0;
      display: inline-block;
      vertical-align: top;
      opacity: 0.5;

      &.arrow-down {
        transform: rotateZ(0deg);
        transition: transform 0.2s;
      }

      &.arrow-up {
        transform: rotateZ(180deg);
        transition: transform 0.2s;
      }
    }

    & > img {
      width: 16px;
      height: 16px;
    }
  }

  & .menu-dropdown {
    position: absolute;
    top: var(--spacing-xl);
    right: 0;
    min-width: 12rem;
    display: none;
    text-align: left;
    vertical-align: middle;
    white-space: nowrap;
    border: var(--box-border);
    border-radius: var(--box-radius);
    box-shadow: 0px 8px 16px -2px var(--shadow-color);
    background-color: var(--background-color);
    z-index: 5;

    // Items styling for MenuItem and custom options
    & > ul > li {
      cursor: pointer;

      &:hover {
        background-color: #f4f4f4;
      }
    }

    & > ul > li > a {
      display: block;
      padding: var(--box-padding);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & > ul > li ~ li {
      border-top: var(--box-border);
    }
  }

  & .menu-left {
    right: auto;
    left: 0;
  }

  & .menu-right {
    right: 0;
    left: auto;
  }

  & .menu-open {
    display: block;
  }
}
</style>
